import React from 'react';

const Tip = ({text}) => {
  return (
    <div>
      <p>
        <svg xmlns="http://www.w3.org/2000/svg" className="inline float-left" viewBox="0 0 24 24" height="40">
          <path
            d="M12 2C8.14 2 5 5.14 5 9c0 2.38 1.62 4.43 4 5.19V16c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1.81c2.38-.76 4-2.81 4-5.19 0-3.86-3.14-7-7-7z"
            fill="#FFD54F"
          />
          <path
            d="M12 18c-1.1 0-2-.9-2-2h4c0 1.1-.9 2-2 2z"
            fill="#424242"
          />
          <path
            d="M12 6.5c.83 0 1.5.67 1.5 1.5 0 .28.22.5.5.5s.5-.22.5-.5c0-1.38-1.12-2.5-2.5-2.5-.28 0-.5.22-.5.5s.22.5.5.5z"
            fill="#FFC107"
          />
          <path
            d="M12 4c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5s-.5.22-.5.5v1c0 .28.22.5.5.5z"
            fill="#FFEB3B"
          />
        </svg>
        <span className='text-gray-600'>{text}</span>
      </p>
    </div>
  )
};

export default Tip;
