import React, { useEffect } from 'react'
import ImageCard from '../ImageCard';
import Tip from '../../components/notifications/flash/Tip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import traceOutline from '../../assets/traceoutline.png';
import orderMagik from '../../assets/orderMagik.png';
import launchSchool from '../../assets/outlineLaunch.png';
import webbernet from '../../assets/webbernet.png';

const Experience = () => {

  const toastifyConstruction = () => {
    toast(<Tip text={'Try clicking on a photo'} />, {
      position: 'top-left',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'bg-yellow-100 rounded font-semibold text-gray-600',
      toastId: 'notifyToast'
    });
  };

  useEffect(() =>{
    toastifyConstruction();
  });

  return (
    <div>
      <div className='flex flex-wrap md:w-full mt-10 md:mt-16 justify-center md:px-3'>
        <ImageCard
          image = {webbernet}
          title = 'Webbernet'
          linkTo={'www.webbernet.com.au'}
          writeUp='Employed as a full-stack developer to build and maintain multiple client applications.'
          tags={['Rails', 'JavaScript', 'Stripe', 'API-Integrations', 'PostgreSQL', 'R-Spec', 'Vue.js', 'React']}
        />

        <ImageCard
          image = {traceOutline}
          linkTo={'www.tracetaiwan.com'}
          title = 'Trace Taiwan'
          writeUp='A web application where users can find, add, save, and edit information and photos about River traces.'
          tags={['Rails', 'JavaScript', 'Heroku', 'TailwindCSS', 'PostgreSQL', 'GoogleMapsAPI']}
        />

        <ImageCard
          image={orderMagik}
          title = 'OrderMagik'
          linkTo={'apps.shopify.com/partners/shiprobot'}
          writeUp='An embedded Shopify application where users can update the fulfillment status, and configure the fulfillment options
           of their orders in bulk by uploading spreadsheet (CSV) files.'
          tags={['Rails', 'JavaScript', 'TailwindCSS', 'Shopify','MongoDB', 'EmbeddedApp', 'SASS']}
        />

        <ImageCard
          image={launchSchool}
          title='Launch School'
          linkTo={'www.launchSchool.com'}
          writeUp='Results oriented and heavily researched learning curriculum designed for a long-term
                  career as a Software Engineer.'
          tags={['Mastery Based Learning', '1500 hours', 'Full-Stack Development']}
        />

      </div>
      <ToastContainer />
    </div>
  )
};

export default Experience;
