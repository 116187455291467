import React from 'react'

const ImageCard = ({image, title, writeUp, tags, linkTo}) => {
  return (
    <div className="max-w-sm md:max-w-md md:w-full py-5 px-3 md:px-7 my-7 md:mx-5 bg-gray-100 rounded overflow-hidden shadow-lg">
      <a href={`https://${linkTo}`} target="_blank" rel="noopener noreferrer" className="block">
        <section className='h-56 flex items-center justify-center'>
          <img
            src={image}
            alt={title}
            className='max-h-56 object-contain px-3 py-1 cursor-pointer'
          />
        </section>
        <div className="px-4">
          <div className="font-bold text-indigo-600 text-xl mb-2 cursor-pointer hover:text-indigo-800 transition-colors">
            {title}
          </div>
        </div>
      </a>
      <div className="px-4 pb-1">
        <p className="text-gray-700 ml-2 text-base">
          {writeUp}
        </p>
      </div>
      <div className="px-2 pt-4 pb-2">
        {tags.map((tag, index) => (
          <span
            key={index}
            className="inline-block bg-gray-200 my-1 rounded-full px-3 py-1 text-sm font-semibold text-gray-600 mr-2"
          >
            #{tag}
          </span>
        ))}
      </div>
    </div>
  )
};

export default ImageCard;
