import React from 'react';
import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import resume from '../assets/gino_resume.pdf';

const BurgerMenu = ({ open, setOpen}) => {
  const showHideClassName = open ? "block" : "hidden";
  const menuLinkStyles = 'my-7 font-bold text-2xl text-indigo-700 hover:underline';
  return (
    <div className={`${showHideClassName} fixed inset-0 min-h-screen bg-indigo-100 opacity-90 z-30 transition duration-700 sm:hidden`}>
      <nav onClick={()=> setOpen(!open)} className='h-full w-full flex items-center justify-center'>
        <div className='flex flex-col items-center'>
          <Link className={menuLinkStyles} to="/">About</Link>
          <Link className={menuLinkStyles} to="/experience">Experience</Link>
          <Link className={menuLinkStyles} to="/contact">Contact</Link>
          <a className={menuLinkStyles} href={resume} target="_blank"
                rel="noopener noreferrer">Resume</a>
        </div>
      </nav>
    </div>
  )
};

BurgerMenu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired
};

export default BurgerMenu;
